import {FC, PropsWithChildren} from 'react';

import {useCustomer, useCustomerData} from 'client/hooks/customer-hooks';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {featuredCollectionProductGroupQuery} from 'client/graphql/queries/featured-collection-queries';
import {useCustomerDataToGetLocationId} from 'client/hooks/location-hooks';
import {EWIC_STORES, PERKS_PRICING} from 'client/enums/feature-toggle-names';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {FeaturedCollectionQuery, FeaturedCollectionQueryVariables} from 'autogen/FeaturedCollectionQuery';

import MerchandisingCard, {IPromo} from '../merchandising-card';
import {useGraphqlSwimlane} from '../hooks/useGraphqlSwimlane';

import FeaturedCollectionSwimlaneOptions from './featured-collection-swimlane-options';
import HomePageSwimlane from './home-page-swimlane';

interface IFeaturedCollectionProps {
    collectionId: number;
    lazyload?: boolean;
    seeAllOverride?: string | null;
    promo?: IPromo | null;
}

const FeaturedCollectionSwimlane: FC<PropsWithChildren<PropsWithChildren<IFeaturedCollectionProps>>> = ({
    collectionId,
    lazyload,
    seeAllOverride,
    promo
}) => {
    const {pickupLocationHasLocker, storeId} = useCustomerData();
    const {fuelSaverCardUuid} = useCustomer();
    const {locationId, loading: locationLoading} = useCustomerDataToGetLocationId();
    const {featureEnabled, featuresEnabled} = useFeatureToggle();
    const perksPricingEnabled = featureEnabled(PERKS_PRICING);

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const option = new FeaturedCollectionSwimlaneOptions(collectionId, seeAllOverride);

    const queryOptions = {
        skip: locationLoading || !collectionId,
        variables: {
            authType: fuelSaverCardUuid ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            locationIds: [locationId],
            pageSize: 10,
            perksPricingEnabled,
            pickupLocationHasLocker,
            productGroupId: collectionId,
            storeId,
            targeted: Boolean(fuelSaverCardUuid),
            wicEnabled
        }
    };

    const {
        data: queryData,
        loading,
        fetchMore
    } = useGraphqlSwimlane<FeaturedCollectionQuery, FeaturedCollectionQueryVariables>(
        option,
        featuredCollectionProductGroupQuery,
        queryOptions
    );

    return (
        <HomePageSwimlane
            PromotionComponent={<MerchandisingCard promo={promo} />}
            aria-label={option.getHeader(queryData)}
            data={option.getItems(queryData)}
            fetchMore={fetchMore}
            header={{
                seeAllUrl: option.getSeeAllUrl(),
                text: option.getHeader(queryData)
            }}
            id={`featured-collection-${collectionId}`}
            key={collectionId}
            lazyload={lazyload}
            loading={loading}
            swimlaneTestId={'featured-collection-swimlane'}
        />
    );
};

export default FeaturedCollectionSwimlane;
